import React from 'react'
import { Link } from 'gatsby'
import { MdMusicNote } from "react-icons/md"

const Post = ({ excerpt, frontmatter }) => {
  const { title, slug, date, category, musicLink, listening } = frontmatter
  return (
      <article className="article">
          <span className="tag article__tag" id={category}>{category}</span>
          <Link to={`/posts/${slug}`} className="link">
          <h2 className="title article__title space is-5">{title}</h2>
          </Link>
          <p className="subtitle subtitle-list">{excerpt}</p>
          <div className="article__meta">
            <p className="article__music">
              <MdMusicNote></MdMusicNote>
              listening to <a href={musicLink} target="_blank" rel="noopener noreferrer" className="article__band"> {listening} </a> on {date}<br />
            </p>
            
          </div>
      </article>
  )
}

export default Post
