import React from 'react'
import SocialLinks from '../../constants/socialLinks'
import { StaticImage } from 'gatsby-plugin-image'
import Title from './Title'

const About = () => {
  return (
    <div className="about">
      <Title title="about me" />
      <div className="about-col">
        <div className="about-radius">
          <StaticImage
            src="../../assets/jennyq.png"
            layout="fixed"
            width={80}
            height={80}
            alt="author"
            className="img about"
          />
        </div>
        <p className="about-text">animal lover. perpetual student.</p>
      </div>
      <SocialLinks styleClass="banner-icons"></SocialLinks>
    </div>
  )
}

export default About
