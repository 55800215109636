import React from 'react'
import Categories from '../Categories'
import Title from './Title'
const BannerCategories = () => {
  return (
    <div className="categories">
      <Title title="categories"></Title>
      <Categories />
    </div>
  )
}

export default BannerCategories
