import React from 'react'
import About from './About'
import ExtLinks from './ExtLinks'
// import Recent from './Recent'
import BannerCategories from './BannerCategories'
const Banner = () => {
  return (
    <aside className="column aside">
      <BannerCategories />
      {/* <Recent /> */}
      <About />
      <ExtLinks />
    </aside>
  )
}



export default Banner
