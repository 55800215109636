import React from 'react'
import Post from './Post'
import Banner from '../Banner'
import { Link } from 'gatsby'
import SeoHelmet from '../SeoHelmet'

const Posts = ({ posts, title }) => {
  return (
    <section className="columns">
      <SeoHelmet title={title} />
      <div className="column is-three-quarters">
        <h1 className="title is-4 space title-header">{title}</h1>
          {posts.map(post => {
            return <Post key={post.id} {...post} />
          })}
          <Link to="/posts" className="button is-link is-outlined">
          view all posts
        </Link>
      </div>
      <Banner />
    </section>
  )
}

export default Posts
