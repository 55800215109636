import React from 'react'
import Title from './Title'

const ExtLinks = () => {
    return (
        <div className="about">
            <Title title="links" />
            <a href="https://soundcloud.com/hotbotpro" target="_blank" className="ext-links" rel="noopener noreferrer">chill beats</a>
            <a href="https://www.jennyq.co/rss.xml" target="_blank" className="ext-links" rel="noopener noreferrer">jennyq rss link</a>
        </div>
    )
}

export default ExtLinks
